const refundPolicyURL =
  'https://fixd.zendesk.com/hc/en-us/articles/360004225852-FIXD-Product-Return'
const privacyPolicyURL = 'https://www.fixdapp.com/privacy'
const termsOfServiceURL = 'https://www.fixdapp.com/terms-of-service'
const subscriptionPolicyURL =
  'https://fixd.zendesk.com/hc/en-us/articles/4403038530843-FIXD-Premium-Refund-Policy'
const supportURL = 'https://fixd.zendesk.com/hc/en-us'
const careersURL = 'https://angel.co/company/fixd-automotive'
const newSupportTicketURL = 'https://fixd.zendesk.com/hc/en-us/requests/new'
const returnPolicyURL = 'https://www.fixdapp.com/returns-warranty'
const aboutPremiumURL = 'https://get.fixdapp.com/premium/home' // TODO: this probably won't be around forever

const social = {
  facebookURL: 'https://www.facebook.com/fixdapp',
  youtubeURL: 'https://www.youtube.com/fixdapp',
  instagramURL: 'https://www.instagram.com/fixd_automotive/',
  twitterURL: 'https://twitter.com/fixd_app',
  tiktokURL: 'https://www.tiktok.com/@fixd',
}

export {
  refundPolicyURL,
  privacyPolicyURL,
  termsOfServiceURL,
  subscriptionPolicyURL,
  supportURL,
  careersURL,
  newSupportTicketURL,
  aboutPremiumURL,
  social,
  returnPolicyURL,
}
